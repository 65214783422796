<template>
  <b-container class="payment-method-form-container">
    <validation-observer ref="observer" v-slot="{ invalid }">
      <b-form-row v-if="showExistingAddressesDropdown">
        <b-col class="col-12">
          <b-form-group>
            <b-form-select
              v-model="selectedExistingAddress"
              :options="availableAddresses()"
            >
              <template v-slot:first>
                <b-form-select-option :value="null">
                  Select Address Option
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-form-row>

      <div v-if="isAddingNewAddress || editAddress" class="card new-address-card">
        <div class="card-body">
          <div class="card-title">
            <h5>
              {{ addressDetailsHeader }}
            </h5>
            <button
              v-if="hasAvailableAddresses && !editAddress"
              class="empty-button"
              @click="closeNewAddress"
            >
              <feather-icon
                v-if="hasAvailableAddresses"
                class="icon"
                :type="'x'"
                :width="16"
                :height="16"
                :stroke="'#1F1F26'"
                :fill="'none'"
              />
            </button>
          </div>

          <b-container>
            <b-form-row>
              <b-col v-if="showContactName" class="col-12">
                <b-form-group label="To:">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Contact Name"
                  >
                    <b-form-input
                      v-model="address.contact_name"
                      type="text"
                      name="contactName"
                      autocomplete="address-contact_name"
                      :state="!errors[0] ? null: false"
                      :readonly="contactNameReadonly"
                    />
                    <b-form-invalid-feedback :state="!errors[0] ? null: false">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col class="col-12">
                <b-form-group label="Line 1:">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="isPhoneContact ? '' : 'required'"
                    name="Line 1 of the Address"
                  >
                    <b-form-input
                      v-model="address.line1"
                      type="text"
                      name="line1"
                      autocomplete="address-line1"
                      :state="!errors[0] ? null: false"
                    />
                    <b-form-invalid-feedback :state="!errors[0] ? null: false">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col class="col-12">
                <b-form-group label="Line 2:">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Line 2 of the Address"
                  >
                    <b-form-input
                      v-model="address.line2"
                      type="text"
                      placeholder="Suite, Apt #..."
                      :state="!errors[0] ? null: false"
                    />
                    <b-form-invalid-feedback :state="!errors[0] ? null: false">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="7">
                <b-form-group label="City:">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="isPhoneContact ? '' : 'required'"
                    name="City"
                  >
                    <b-form-input
                      v-model="address.city"
                      type="text"
                      :state="!errors[0] ? null: false"
                    />
                    <b-form-invalid-feedback :state="!errors[0] ? null: false">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-if="showStateProvince" cols="5">
                <b-form-group v-if="usCountrySelected" label="State:">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="isPhoneContact ? '' : 'required'"
                    name="State"
                  >
                    <b-form-select
                      v-model="address.state_province_region"
                      :options="jurisdictionsOptions"
                      :state="!errors[0] ? null: false"
                      :disabled="!usCountrySelected"
                    >
                      <template slot="first">
                        <b-form-select-option :value="null" disabled>
                          Select a State
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback :state="!errors[0] ? null: false">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-else-if="canadaSelected" label="Province:">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="isPhoneContact ? '' : 'required'"
                    name="Province"
                  >
                    <b-form-select
                      v-model="address.state_province_region"
                      :options="caProvinces()"
                      :state="!errors[0] ? null: false"
                      :disabled="!canadaSelected"
                    >
                      <template slot="first">
                        <b-form-select-option :value="null" disabled>
                          -- Select a Province --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback :state="!errors[0] ? null: false">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-else label="State/Province:">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="isPhoneContact ? '' : (stateProvinceRequired ? 'required' : '')"
                    name="State Or Province"
                  >
                    <b-form-input
                      v-model="address.state_province_region"
                      type="text"
                    />
                    <b-form-invalid-feedback :state="!errors[0] ? null: false">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col v-if="showPostalCode" :class="showKind ? 'col-3' : 'col-6'" cols="12" md="4">
                <b-form-group v-if="usCountrySelected" label="Zipcode:">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="isPhoneContact ? 'zipcode' : 'required|zipcode'"
                    name="Zipcode"
                  >
                    <b-form-input
                      v-model="address.zip_postal_code"
                      type="text"
                      maxlength="10"
                      :state="!errors[0] ? null: false"
                    />
                    <b-form-invalid-feedback :state="!errors[0] ? null: false">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-else label="Zipcode:">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="isPhoneContact ? '' : (postalCodeRequired ? 'required' : '')"
                    name="Zipcode"
                  >
                    <b-form-input
                      v-model="address.zip_postal_code"
                      type="text"
                      :state="!errors[0] ? null: false"
                    />
                    <b-form-invalid-feedback :state="!errors[0] ? null: false">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col :class="showKind ? 'col-5' : 'col-6'" cols="12" md="8">
                <b-form-group label="Country:">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Country"
                  >
                    <b-form-select
                      v-model="address.country"
                      :options="countries()"
                      :state="!errors[0] ? null: false"
                    />
                    <b-form-invalid-feedback :state="!errors[0] ? null: false">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col v-if="showKind" class="col-12" cols="12" md="12">
                <b-form-group label="Kind:">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Kind"
                  >
                    <b-form-select
                      v-model="address.kind"
                      :options="kindOptions"
                      :state="!errors[0] ? null: false"
                    />
                    <b-form-invalid-feedback :state="!errors[0] ? null: false">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-container>
        </div>
      </div>
    </validation-observer>
  </b-container>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { countries } from '@/common/modules/countries'
import { caProvinces } from '@/common/modules/caProvinces'
import { xpsFields } from '@/common/modules/xpsFields'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AddressFormV2',
  components: {
    FeatherIcon: () => import('@/components/shared/FeatherIcon'),
    ValidationProvider,
  },
  props: {
    allowExistingAddresses: {
      type: Boolean,
      default: false,
    },
    showKind: {
      type: Boolean,
      default: true,
    },
    editAddress: {
      type: Object,
      default: null,
    },
    kind: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    showContactName: {
      type: Boolean,
      default: false,
    },
    contactNameReadonly:{
      type: Boolean,
      default: false,
    },
    isPhoneContact: {
      type: Boolean,
      default: false,
    },
    isAddingNewAddress: {
      type: Boolean,
      default: false,
    },
    hasAvailableAddresses: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return this.initialData()
  },
  computed: {
    ...mapGetters('account', [
        'account',
        'addresses',
        'people',
    ]),
    ...mapGetters('jurisdictions', [
      'allJurisdictions',
    ]),
    usCountrySelected() {
      return this.address.country === 'US'
    },
    canadaSelected() {
      return this.address.country === 'CA'
    },
    postalCodeRequired() {
      return this.xpsFields().postalCodeRequired.includes(this.address.country)
    },
    showPostalCode() {
      return this.postalCodeRequired
        || this.xpsFields().postalCodeOptional.includes(this.address.country)
    },
    stateProvinceRequired() {
      return this.xpsFields().stateProvinceRequired.includes(this.address.country)
    },
    showStateProvince() {
      return this.stateProvinceRequired
        || this.xpsFields().stateProvinceOptional.includes(this.address.country)
    },
    showExistingAddressesDropdown() {
      return this.allowExistingAddresses
        && this.availableAddresses().length > 0
        && !this.isAddingNewAddress
    },
    countryNeedsFormatting() {
      return this.address.country.length > 2
    },
    jurisdictionsOptions() {
      const options = this.allJurisdictions.map(jurisdiction => ({
        text: jurisdiction.state_province_region,
        value: jurisdiction.abbreviation,
      }))

      options.unshift({ text: 'Other', value: '' })
      return options
    },
    addressDetailsHeader() {
      return `${this.editAddress ? 'Update' : 'New'} Address Details`
    },
  },
  watch: {
    address: {
      deep: true,
      handler() {
        this.$emit('changed', this.address, this.index)
      },
    },
    selectedExistingAddress(newAddress) {
      if (newAddress != null) {
        this.address = { ...newAddress }
        if (this.countryNeedsFormatting()) this.formatCountry()
      } else {
        this.resetAddressFields()
      }
    },
    editAddress: {
      handler(newVal) {
        if (newVal) {
          this.address = { ...newVal }
          this.selectedExistingAddress = this.findMatchingAddress(newVal)
        }
      },
      deep: true,
      immediate: true,
    },
    kind() {
      if (this.kind !== '') this.address.kind = this.kind
    },
  },
  async beforeMount() {
    if (this.allowExistingAddresses) await this.loadAccount()
  },
  async mounted() {
    if (this.editAddress) {
      this.address = { ...this.editAddress }
      this.selectedExistingAddress = this.findMatchingAddress(this.editAddress)
    }
    if (this.kind.trim() !== '') this.address.kind = this.kind

    if (!this.jurisdictions?.length) await this.loadJurisdictions()
  },
  methods: {
    ...mapActions('account', [
      'updatePersonAddress',
      'createPersonAddress',
      'loadAccount',
    ]),
    ...mapActions('jurisdictions', {
      loadJurisdictions: 'load',
    }),
    caProvinces() {
      return caProvinces.map((provinceObj) => [{
        text: provinceObj.stateProvinceRegion,
        value: provinceObj.abbreviation,
      }]).flat()
    },
    countries: () => countries,
    xpsFields: () => xpsFields,
    availableAddresses() {
      return this.addresses.map((address) => [{
        text: this.getAvailableAddressText(address),
        value: address,
      }]).flat()
    },
    getAvailableAddressText(address){
      return `${address.line1}, ${address.city}, ${address.state_province_region} ${address.zip_postal_code}, ${address.country}`
    },
    formatCountry() {
      countries.every(country => {
        if(this.address.country === country['text']){
          this.address.country = country['value']
          return false
        }
        return true
      })
    },
    errorToast(error) {
      this.$bvToast.toast(error, { title: 'Error', variant: 'danger' })
    },
    initialData() {
      return {
        editInfo: null,
        address: {
          line1: null,
          line2: null,
          zip_postal_code: null,
          city: null,
          state_province_region: null,
          country: 'US',
          kind: 'primary',
          contact_name: null,
        },
        kindOptions: ['mailing', 'alternate', 'billing', 'primary', 'physical'],
        selectedExistingAddress: null,
      }
    },
    resetAddressFields() {
      this.selectedExistingAddress = null
      this.address = {
        line1: null,
        line2: null,
        zip_postal_code: null,
        city: null,
        state_province_region: null,
        country: "US",
        kind: "primary",
        contact_name: null,
      }

      this.$emit('changed', this.address, this.index)
    },
    findMatchingAddress(address) {
      const match = this.addresses.find(
        (addr) => addr.line1 === address.line1 &&
          addr.line2 === address.line2 &&
          addr.city === address.city &&
          addr.state_province_region === address.state_province_region &&
          addr.zip_postal_code === address.zip_postal_code &&
          addr.country === address.country
      )
      return match ? match : null
    },
    closeNewAddress() {
      this.resetAddressFields()
      this.$emit('close-new-address', true)
    },
  },
}
</script>

<style scoped lang="scss">
.payment-method-form-container {

  ::v-deep .form-row:first-child {
    margin-bottom: 1.0rem;
  }

  .new-address-show {
    display: block;
  }

  .new-address-hide {
    display: none;
  }

  ::v-deep .new-address-card {
    margin-left: 0.5rem !important;
    margin-bottom: 1.0rem !important;
  }

  ::v-deep .card {
    margin: 0 0.6rem;

    .card-body {
      padding: 1.0rem;

      .card-title {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        h5 {
          margin-bottom: 0;
          font-size: 1.0rem;
          font-weight: 400;
          color: #4E4E52;
        }

        .empty-button {
          background: white;
          border: 0;

          .icon {
            margin-right: 0;
          }
        }
      }

      .container {
        padding: 0;

        .form-row {
          .col, .col-5, .col-8, .col-12 {
            padding: 0 !important;
          }

          .col-4, .col-7, .col-md-4 {
            padding-left: 0 !important;
            padding-right: 0.5rem  !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .payment-method-form-container {
    padding: 0 !important;

    ::v-deep .form-row .col .multi-line-option {
      max-width: 90%;
      max-height: 4.0rem;
      height: 100%;
      margin-left: 1.0rem !important;
      white-space: break-spaces;
    }

    ::v-deep .card {
      margin: 0;

      .card-body .container .form-row {

        .col-4, .col-7 {
          padding-right: 0.5rem;
        }
      }
    }
  }
}
</style>
